
































import { Component, Vue } from 'vue-property-decorator';
import Severity, {getSeveritySelectItems} from "@/types/Severity";
import {CreateRuleDTO, OutputDeviceTypeDTO} from "@/api";
import Loading from "@/components/Loading.vue";

@Component({
  components: {Loading}
})
export default class RulesCreate extends Vue {

  public messageTypes: string[] = ['STATE', 'EVENT'];
  public severities = getSeveritySelectItems()

  public name: string = '';
  public messageType: string = this.messageTypes[0];
  public severity: string = Severity.INFO;
  public deviceTypeId: number|null|undefined = null;
  public note: string = '';
  public jsonRule: string = '';

  public deviceTypes: OutputDeviceTypeDTO[]|null = null;

  public valid = false;

  public async mounted() {
    this.reset();
    await this.fetchDeviceTypes();
  }

  public async fetchDeviceTypes() {
    this.deviceTypes = await this.$store.dispatch('deviceTypes/getAll');
  }

  public reset() {
    this.name = '';
    this.messageType = this.messageTypes[0];
    this.severity = Severity.INFO
    this.deviceTypeId = this.deviceTypes !== null ? this.deviceTypes[0].id! : null;
    this.note = ''
    this.jsonRule = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const dto = {
        device_type_id: this.deviceTypeId,
        name: this.name,
        rule_data: JSON.parse(this.jsonRule),
        note: this.note,
        severity: this.severity,
        message_type: this.messageType
      } as CreateRuleDTO

      await this.$store.dispatch('rules/create', { data: dto });

      await this.$router.push('/main/admin/rules/all');
    }
  }

  public validJsonRule(x) {
    try {
        JSON.parse(x);
    } catch (e) {
        return 'Invalid JSON data';
    }
    return true;
  }

  get deviceTypeSelectItems() {
    if (this.deviceTypes === null) {
      return [];
    }

    return this.deviceTypes.map(deviceType => {
      return {
        text: deviceType.name,
        value: deviceType.id
      }
    })
  }

  get loading() {
    return this.deviceTypes === null;
  }
}
